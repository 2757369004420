import { ArrowButton } from 'components/_universal/ArrowButton';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { BigNumberHeader, SocialMediaIconsLeft } from 'pages/404';
import React from 'react';
import { ROUTE_HOME } from 'shared/paths';
import { B3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const NotFoundInfo = () => {
    const isMobile = useBreakpoint('md');

    return (
        <Common.Div flex="row" justifyContent="center" alignItems="center">
            <Common.Div flex={isMobile ? 'column' : 'row'} gap="30px">
                <BigNumberHeader>404</BigNumberHeader>
                <Common.Div flex="column">
                    <B3 mb={4}>
                        Sorry, the page you are looking for could not be found.{' '}
                        <br />
                        Go to the home page using the button below.
                    </B3>
                    <ArrowButton
                        onClick={() => navigate(ROUTE_HOME)}
                        text="home page"
                    />
                    <SocialMediaIconsLeft />
                </Common.Div>
            </Common.Div>
        </Common.Div>
    );
};
